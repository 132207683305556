import { scaleTime } from 'd3';
import {
    Grid,
    onMouseMove,
    onTouchMove,
    Scale,
} from 'd3-charts-react';
import { useMemo } from 'react';
import { Axis, axisPropsFromTickScale, LEFT } from 'react-d3-axis';
import { useResizeDetector } from 'react-resize-detector';

import styles from './PowerBalanceChart.module.scss';

import FtpDisplay from '../thresholdDisplay/ftpDisplay';

import AltitudeChart from '../../altitudeChart';
import BikeComponentLink from '../../bikeComponentLink';
import { extractTime, extractValue } from '../../chartHelpers';

import Translate from '../../../../components/translate';
import {
    GREEN_LIGHT,
    RED_LIGHT,
    roundValueBy,
    SRAM_200,
    toLocaleString,
} from '../../../../constants';
import { useSetState } from '../../../../hooks';
import { ComponentSummary, useUnits } from '../../../../providers';
import ExpandChart from '../../../../views/expandChart';

interface PowerBalanceChartProps {
    disableEdit: boolean,
    gpsComponent: ComponentSummary,
    powerComponent: ComponentSummary
}

const BUBBLE_RADIUS = 5;
const DEFAULT_COLOUR = RED_LIGHT;
const DEFAULT_OPACITY = 0.1;
const SCALE_Y_MAX = 100;
const SCALE_Y_MIN = 0;
const SELECTED_COLOUR = GREEN_LIGHT;
const SELECTED_OPACITY = 1;
const DEFAULT_STATE = { altitude: 0, hoveredPowerBalanceIndex: null, timestamp: null };

/**
 * Formats the power balance value to be read by the user
 * @param {Number} powerBalance Power balance, value between 0-100
 * @return {String} "50/50", "{percentage}% L" or "{percentage}% R"
 */

function Circle({ powerComponent, scale }: { powerComponent: any, scale: any}) {
    const timeArray = useMemo(() => powerComponent.data.power_balance.map((value: any, index: number) => {
        const time = extractTime(powerComponent, value, index);

        return { time, value };
    }), [scale, powerComponent.data.power_balance]);

    return timeArray.map(({ time, value }: any) => (
        <circle
            // Don't render outside the chart x axis
            cx={Math.max(scale.x(time), BUBBLE_RADIUS)}
            cy={scale.y(extractValue(value))}
            fill={DEFAULT_COLOUR}
            key={time}
            opacity={DEFAULT_OPACITY}
            r={BUBBLE_RADIUS}
        />
    ));
}

function formatPowerBalance(powerBalance: number) {
    const powerBalanceValue = extractValue(powerBalance);

    if (!Number.isFinite(powerBalance)) return '0%';

    // Round powerBalance to 2dp
    const roundedPowerBalance = roundValueBy(powerBalanceValue, 2);

    // powerBalance is even
    if (roundedPowerBalance === 50) return '50/50';

    // powerBalance is on the left side
    if (roundedPowerBalance > 50) {
        const percentageLeft = powerBalanceValue;

        return `${roundValueBy(percentageLeft, 2)}% L`;
    }

    // powerBalance is on the right side
    const percentageRight = (100 - powerBalanceValue);

    return `${roundValueBy(percentageRight, 2)}% R`;
}

function PowerBalanceChart({
    disableEdit = false,
    gpsComponent,
    powerComponent,
}: PowerBalanceChartProps) {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const units = useUnits();
    const { altitude, hoveredPowerBalanceIndex, timestamp } = state;

    const renderIndicator = (scale: any) => {
        if (!Number.isFinite(hoveredPowerBalanceIndex)) return null;

        const powerBalance = extractValue(powerComponent.data.power_balance[hoveredPowerBalanceIndex]);

        let bubbleX = scale.x(extractTime(powerComponent, powerBalance, hoveredPowerBalanceIndex));
        // Don't render outside the chart x axis
        if (bubbleX < BUBBLE_RADIUS) {
            bubbleX = BUBBLE_RADIUS;
        }

        return (
            <circle
                cx={bubbleX}
                cy={scale.y(extractValue(powerBalance))}
                fill={SELECTED_COLOUR}
                opacity={SELECTED_OPACITY}
                r={BUBBLE_RADIUS}
            />
        );
    };

    const renderChart = (scale: any) => {
        const xAxisProps = axisPropsFromTickScale(scale.x, 3);
        const yAxisProps = axisPropsFromTickScale(scale.y);

        const width = scale.x.range()[1];
        const height = scale.y.range()[0];

        return (
            <div className={styles.chartContainer}>
                <svg
                    className={styles.chart}
                    height={height}
                    onMouseLeave={() => setState({ altitude: 0, hoveredPowerBalanceIndex: null, timestamp: null })}
                    onMouseMove={(event) => {
                        const hoveredTimeStamp = onMouseMove(event, scale);

                        if (hoveredTimeStamp) {
                            setState({
                                hoveredPowerBalanceIndex: (
                                    Math.floor(hoveredTimeStamp / 1000) - powerComponent.data.adjustedTime[0]
                                ),
                                timestamp: hoveredTimeStamp,
                            });
                        }
                    }}
                    onTouchMove={(event) => {
                        const hoveredTimeStamp = onTouchMove(event, scale);

                        if (hoveredTimeStamp) {
                            setState({
                                hoveredPowerBalanceIndex: (
                                    Math.floor(hoveredTimeStamp / 1000) - powerComponent.data.adjustedTime[0]
                                ),
                                timestamp: hoveredTimeStamp,
                            });
                        }
                    }}
                    width={width}
                >
                    <AltitudeChart
                        gpsComponent={gpsComponent}
                        height={height}
                        onAltitudeChange={(value) => setState({ altitude: value })}
                        sync={timestamp}
                        width={width}
                    />
                    <Grid
                        hideXTicks
                        scale={scale}
                        yLineStyle={{ stroke: SRAM_200 }}
                        yTicks={yAxisProps.values}
                    />
                    <Circle powerComponent={powerComponent} scale={scale} />
                    {renderIndicator(scale)}
                    <g className={styles.axis}>
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...yAxisProps}
                            values={[scale.y.domain()[1], 50, scale.y.domain()[0]]}
                            format={(value: number) => {
                                switch (value) {
                                    case scale.y.domain()[1]:
                                        return '100% L';
                                    case 50:
                                        return '50/50';
                                    case scale.y.domain()[0]:
                                        return '100% R';
                                    default:
                                        return '';
                                }
                            }}
                            style={{ orient: LEFT }}
                        />
                    </g>
                    <g
                        className={styles.axis}
                        style={{ transform: `translateY(${height}px)` }}
                    >
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...xAxisProps}
                            format={units.formatTime}
                        />
                    </g>
                </svg>
                <div className={styles.mobileTimeScale}>
                    <div className={styles.startTime}>
                        {units.formatTime(powerComponent.adjustedStartTs * 1000)}
                    </div>
                    <div className={styles.endTime}>
                        {units.formatTime(powerComponent.adjustedEndTs * 1000)}
                    </div>
                </div>
            </div>
        );
    };

    const { width, height, ref } = useResizeDetector();

    const yScaleBubbleRadiusAdjustment = SCALE_Y_MAX * (BUBBLE_RADIUS / (height || 400));
    const renderScale = () => (
        <div ref={ref}>
            <Scale
                height={height}
                width={width}
                xMax={powerComponent.adjustedEndTs * 1000}
                xMin={powerComponent.adjustedStartTs * 1000}
                xScale={scaleTime}
                yMax={SCALE_Y_MAX + yScaleBubbleRadiusAdjustment}
                yMin={SCALE_Y_MIN - yScaleBubbleRadiusAdjustment}
            >
                {(scale: any) => renderChart(scale)}
            </Scale>

        </div>
    );

    if (!powerComponent) return null;

    if (!powerComponent.data.power_balance.length) {
        return (
            <div className={styles.noDataNote}>
                <Translate>NO_DATA_AVAILABLE_NOTES</Translate>
            </div>
        );
    }

    return (
        <ExpandChart title="POWER_BALANCE">
            <div className={styles.container}>
                <div className={styles.statisticsContainer}>
                    <div className={styles.statistic}>
                        {formatPowerBalance(
                            powerComponent.data.power_balance[hoveredPowerBalanceIndex],
                        )}
                    </div>
                    <div className={styles.statistic}>
                        {Number.isFinite(altitude) && (
                            <>
                                {toLocaleString(altitude)}
                                &nbsp;
                                <Translate>{units.getLabelAltitude().shorthand}</Translate>
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.yAxisLabel}>
                    <Translate>POWER_BALANCE</Translate>
                </div>
                {renderScale()}
                <div className={styles.xAxisLabel}>
                    <Translate>TIME</Translate>
                </div>
                {!disableEdit && (
                    <div className={styles.footer}>
                        <FtpDisplay />
                        <BikeComponentLink componentSummary={powerComponent} />
                    </div>
                )}
            </div>
        </ExpandChart>
    );
}

export default PowerBalanceChart;
